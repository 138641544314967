import React, { useMemo } from 'react';
import { Link, ImageLink } from '~/shared/components';
import {
    StyledThreeColumnList,
    StyledListItem,
    StyledImage,
    StyledMetaInfo,
    StyledListItemTitle,
} from './styled';
import Masonry from 'react-masonry-css';
import {
    RelevantContentItem,
    RelevantContentResponse,
    RelewiseResponsePageTags,
} from '~/lib/data-contract';
import fallbackImage from '$assets/fallback-product.svg';
import NextLink from 'next/link';
import { TagListLink } from '../TagListLink';
import { breaks } from '~/theme';
interface ThreeColumnProps {
    items?: RelevantContentResponse[];
}

export const ThreeColumn = ({ items }: ThreeColumnProps) => {
    const breakpointColumnsObj = {
        default: 3,
        700: 2,
        500: 1,
    };

    const relevantItems = useMemo(() => {
        if (!items) return [];

        const itemsList: RelevantContentItem[] = [];
        items.forEach((group) => {
            itemsList.push(...group.relevantContent);
        });

        return itemsList;
    }, [items]);

    return (
        <StyledThreeColumnList>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="three-column-grid"
                columnClassName="three-column-grid_column"
            >
                {relevantItems.map((item) => {
                    return (
                        <StyledListItem
                            key={`threeColumnGrid_${item.callToAction}_${item.headline}`}
                        >
                            <StyledImage>
                                {item.image?.src ? (
                                    <ImageLink
                                        withBorderRadius
                                        imageProps={{
                                            ...item.image,
                                            height: item.image.height,
                                            width: item.image.width,
                                            layout: 'responsive',
                                            draggable: false,
                                            sizes: `(max-width: ${breaks.sm}px) 100vw, (max-width: ${breaks.pageMax}px) 33vw, 460px`,
                                        }}
                                        linkProps={{
                                            href: item.callToAction as string,
                                            passHref: true,
                                        }}
                                    />
                                ) : (
                                    <ImageLink
                                        withBorderRadius
                                        imageProps={{
                                            src: fallbackImage,
                                            layout: 'responsive',
                                            sizes: `(max-width: ${breaks.pageMax}px) 33vw, 460px`,
                                        }}
                                        linkProps={{
                                            href: item.callToAction as string,
                                            passHref: true,
                                        }}
                                    />
                                )}
                            </StyledImage>
                            <NextLink
                                href={item.callToAction as string}
                                passHref
                                prefetch={false}
                                legacyBehavior
                            >
                                <Link>
                                    <StyledListItemTitle variant="display5">
                                        {item.headline}
                                    </StyledListItemTitle>
                                </Link>
                            </NextLink>

                            <StyledMetaInfo>
                                {(JSON.parse(item.tags) as RelewiseResponsePageTags[])
                                    .map((item) => item.tagsList)
                                    .map(
                                        (tags) =>
                                            tags &&
                                            tags.map((tag, index) => (
                                                <TagListLink key={index}>{tag}</TagListLink>
                                            )),
                                    )}
                            </StyledMetaInfo>
                        </StyledListItem>
                    );
                })}
            </Masonry>
        </StyledThreeColumnList>
    );
};
